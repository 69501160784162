import React from "react"
import { Container, Typography } from "@material-ui/core"
import { Router } from "@reach/router"
import ActivateUser from "../components/Sign/ActivateUser"
import ActivateError from "../components/Sign/ActivateError"
import VerifyAccount from "../components/Sign/VerifyAccount"
import Layout from "../components/Layout/layout"
import { useParams } from "@reach/router"
import { useLocation } from "@reach/router"

// route: /user/:userName

const Activate = props => {
  // const confirm = Object.values(props.props.params).shift()
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var token = searchParams.getAll("act")

  // const token = location.search.slice(5)
  return (
    <Layout>
      {/* {JSON.stringify(token)} */}
      <ActivateUser token={token} />
      {/* <Container> */}
      {/* <Typography>activate</Typography>
        <Typography>{token}</Typography>
        {/* {JSON.stringify(loco)} */}
      {/* </Container> */}
      {/* <Router>
        <ActivateUser path="/activate/:activation" props={props} />
      </Router> */}
    </Layout>
    // <>
    //   <ActivateUser path="./:activation" props={props} />
    // </>
  )
}

export default Activate
