import React, { useState, useContext, useEffect } from "react"

import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import { Link, navigate } from "gatsby"

import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"

import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { makeStyles } from "@material-ui/core/styles"

// import Layout from "../Layout/layout"
import FindPerson from "./FindPerson"
import UserContext from "../../context/UserContext"

import Loading from "../utils/Loading"
import Error from "../utils/Error"
import UserHome from "../User/UserHome"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const ActivateUser = ({ token }) => {
  const { state, dispatch } = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [verified, setVerified] = useState(state.isVerified)
  const classes = useStyles()

  // const isVerified = state.isVerified

  useEffect(() => {
    if (verified) {
      navigate("/my")
    }
  }, [verified])

  const [handleConfirmUser, { loading, error }] = useMutation(
    CONFIRM_USER_MUTATION,
    {
      variables: {
        formToken: token,
      },
      onCompleted: data => {
        let verifiedStatus = data.confirmUser.user.isVerified
        dispatch({
          type: "SET_VERIFIED",
          payload: verifiedStatus,
        })
        setVerified(verifiedStatus)
        if (verifiedStatus === true) {
          dispatch({
            type: "SET_LOGIN",
          })
        }
        setEmail("")
        setPassword("")
        navigate("/my")
      },
    }
  )

  const [handleSubmit] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password,
    },
    onCompleted: data => {
      handleConfirmUser()
    },
  })

  const handleSkipLogin = event => {
    handleConfirmUser()
  }
  if (loading) return <Loading />
  //   if (error) return <Error />
  if (verified === true) return <UserHome path="/my" />

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Activate Your Account
          </Typography>
          <Typography component="p" variant="body1">
            Please sign in to activate your account
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={event => {
              event.preventDefault()
              handleSubmit(event)
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              //   disabled={loading || !email.trim() || !password.trim()}
            >
              Activate Your Account
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  component="button"
                  bariant="body2"
                  //   onClick={e => setNewUser(false)}
                >
                  {"Forgot your password?"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  )
}

const CONFIRM_USER_MUTATION = gql`
  mutation($formToken: String!) {
    confirmUser(formToken: $formToken) {
      user {
        email
        isVerified
      }
    }
  }
`

const CHECK_ME_QUERY = gql`
  query {
    me {
      isVerified
      person {
        firstName
        lastName
      }
    }
  }
`

const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`
export default ActivateUser
